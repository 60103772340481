import { flagsmithCheckIsFeatureEnabled } from '../util/api';

const isFeatureEnabled = async feature => {
  return await flagsmithCheckIsFeatureEnabled({ feature: feature })
    .then(isEnabled => {
      return isEnabled;
    })
    .catch(err => {
      console.error(err);
      return false;
    });
};

export const flagsmithService = {
  isFeatureEnabled,
};
