import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { camelize } from '../../util/string';
import { EE_LOCALE, RU_LOCALE } from '../../util/types';

const TERMS_OF_SERVICE = 'terms-of-service';

export let ASSET_NAME;

export const loadData = (params, search, config) => dispatch => {
  const { locale } = config.localization;

  if (locale === EE_LOCALE || locale === RU_LOCALE) {
    ASSET_NAME = TERMS_OF_SERVICE + '-' + locale;
  } else {
    ASSET_NAME = TERMS_OF_SERVICE;
  }

  const pageAsset = { [camelize(ASSET_NAME)]: `content/pages/${ASSET_NAME}.json` };
  return dispatch(fetchPageAssets(pageAsset, true));
};
