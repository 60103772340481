import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconReviewStar } from '../../components';
import { REVIEW_RATINGS } from '../../util/types';

const ReviewRating = props => {
  const {
    className,
    rootClassName,
    reviewStarClassName,
    reviewAverageValueClassName,
    reviewCountClassName,
    showAverageRatingValue,
    ratingCount,
    rating,
  } = props;

  const classes = classNames(rootClassName, className);

  return (
    <span className={classes}>
      <IconReviewStar key={`star-1`} className={reviewStarClassName} isFilled={1 <= rating} />
      {showAverageRatingValue && (
        <span className={reviewAverageValueClassName}>{rating.toFixed(2)}</span>
      )}
      {ratingCount && <span className={reviewCountClassName}>({ratingCount})</span>}
    </span>
  );
};

ReviewRating.defaultProps = {
  rootClassName: null,
  className: null,
  reviewStarClassName: null,
  reviewAverageValueClassName: null,
  reviewCountClassName: null,
  showAverageRatingValue: false,
  ratingCount: null,
};

const { string, boolean, number, oneOf } = PropTypes;

// add 0 to the list of possible ratings
REVIEW_RATINGS.push(0);

ReviewRating.propTypes = {
  rating: oneOf(REVIEW_RATINGS).isRequired,
  reviewStartClassName: string,
  reviewAverageValueClassName: string,
  reviewCountClassName: string,
  showAverageRatingValue: PropTypes.bool,
  ratingCount: number,
  rootClassName: string,
  className: string,
};

export default ReviewRating;
