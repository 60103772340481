import React from 'react';
import { string, oneOfType, bool } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  ensureUser,
  ensureCurrentUser,
  userDisplayNameAsString,
  userAbbreviatedName,
} from '../../util/data';
import { ResponsiveImage, IconBannedUser, NamedLink } from '../../components/';

import css from './Avatar.module.scss';

// Responsive image sizes hint
const AVATAR_SIZES = '40px';
const AVATAR_SIZES_MEDIUM = '60px';
const AVATAR_SIZES_LARGE = '96px';

const AVATAR_IMAGE_VARIANTS = [
  // 40x40
  'square-xsmall',

  // 80x80
  'square-xsmall2x',

  // 240x240
  'square-small',

  // 480x480
  'square-small2x',
];

export const AvatarComponent = props => {
  const {
    rootClassName,
    className,
    initialsClassName,
    user,
    renderSizes,
    disableProfileLink,
    intl,
    useHexagon,
  } = props;

  const rootDivClass = useHexagon ? css.rootHexagon : null;
  const rootInitialsDivClass = useHexagon ? css.rootHexagonInitials : rootDivClass;
  const baseClass = useHexagon ? css.avatarBaseHex : css.root;
  const classes = classNames(rootClassName || baseClass, className);
  const avatarClasses = useHexagon ? css.avatarHexImage : css.avatarImage;
  const initialClasses = useHexagon ? css.initialsHex : css.initials;

  const userIsCurrentUser = user && user.type === 'currentUser';
  const avatarUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

  const isBannedUser = avatarUser.attributes.banned;
  const isDeletedUser = avatarUser.attributes.deleted;

  const defaultUserDisplayName = isBannedUser
    ? intl.formatMessage({ id: 'Avatar.bannedUserDisplayName' })
    : isDeletedUser
    ? intl.formatMessage({ id: 'Avatar.deletedUserDisplayName' })
    : '';

  const defaultUserAbbreviatedName = '';

  const displayName = userDisplayNameAsString(avatarUser, defaultUserDisplayName);
  const abbreviatedName = userAbbreviatedName(avatarUser, defaultUserAbbreviatedName);
  const rootProps = { className: classes, title: displayName };
  const linkProps = avatarUser.id
    ? { name: 'ProfilePage', params: { id: avatarUser.id.uuid } }
    : { name: 'ProfileBasePage' };
  const hasProfileImage = avatarUser.profileImage && avatarUser.profileImage.id;
  const profileLinkEnabled = !disableProfileLink;

  const hexagonBorderRadius = (
    <svg
      className={css.hexagonBorderRadiusFilter}
      width="0"
      height="0"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <defs>
        <filter id="hexagonBorderRadius">
          <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
            result="goo"
          />
          <feComposite in="SourceGraphic" in2="goo" operator="atop" />
        </filter>
      </defs>
    </svg>
  );

  if (isBannedUser || isDeletedUser) {
    return (
      <div {...rootProps}>
        <IconBannedUser className={css.bannedUserIcon} />
      </div>
    );
  } else if (hasProfileImage && profileLinkEnabled) {
    return (
      <div className={rootDivClass}>
        <NamedLink {...rootProps} {...linkProps}>
          <ResponsiveImage
            rootClassName={avatarClasses}
            alt={displayName}
            image={avatarUser.profileImage}
            variants={AVATAR_IMAGE_VARIANTS}
            sizes={renderSizes}
          />
          {hexagonBorderRadius}
        </NamedLink>
      </div>
    );
  } else if (hasProfileImage) {
    return (
      <div className={rootDivClass}>
        <span {...rootProps}>
          <ResponsiveImage
            rootClassName={avatarClasses}
            alt={displayName}
            image={avatarUser.profileImage}
            variants={AVATAR_IMAGE_VARIANTS}
            sizes={renderSizes}
          />
          {hexagonBorderRadius}
        </span>
      </div>
    );
  } else if (profileLinkEnabled) {
    // Placeholder avatar (initials)
    return (
      <div className={rootInitialsDivClass}>
        <NamedLink {...rootProps} {...linkProps}>
          <span className={initialsClassName || initialClasses}>{abbreviatedName}</span>
        </NamedLink>
        {hexagonBorderRadius}
      </div>
    );
  } else {
    // Placeholder avatar (initials)
    return (
      <div className={rootInitialsDivClass}>
        <div {...rootProps}>
          <span className={initialsClassName || initialClasses}>{abbreviatedName}</span>
        </div>
        {hexagonBorderRadius}
      </div>
    );
  }
};

AvatarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  user: null,
  renderSizes: AVATAR_SIZES,
  disableProfileLink: false,
  useHexagon: false,
};

AvatarComponent.propTypes = {
  rootClassName: string,
  className: string,
  user: oneOfType([propTypes.user, propTypes.currentUser]),

  renderSizes: string,
  disableProfileLink: bool,
  useHexagon: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Avatar = injectIntl(AvatarComponent);

export default Avatar;

export const AvatarSmall = props => (
  <Avatar
    rootClassName={css.smallAvatar}
    initialsClassName={css.initialsSmall}
    renderSizes={AVATAR_SIZES_MEDIUM}
    {...props}
  />
);
AvatarSmall.displayName = 'AvatarSmall';

export const AvatarMedium = props => (
  <Avatar
    rootClassName={css.mediumAvatar}
    initialsClassName={css.initialsMedium}
    renderSizes={AVATAR_SIZES_MEDIUM}
    {...props}
  />
);
AvatarMedium.displayName = 'AvatarMedium';

export const AvatarLarge = props => {
  const { useHexagon } = props;
  return (
    <Avatar
      rootClassName={useHexagon ? css.largeAvatarHex : css.largeAvatar}
      initialsClassName={useHexagon ? css.initialsLargeHex : css.initialsLarge}
      renderSizes={AVATAR_SIZES_LARGE}
      {...props}
    />
  );
};
AvatarLarge.displayName = 'AvatarLarge';
