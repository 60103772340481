export const dislyMenu = [
  {
    name: 'FOTO/VIDEO',
    title: 'Foto ja video jäädvustused igaks hetkeks',
    ahref: '/search/foto-video',
    items: [
      {
        name: 'Pulmafotograafia',
        title: 'Pulmafotograafia erilisteks hetkedeks',
        description:
          'Kogenud pulmafotograaf jäädvustab kõige tähtsamad mälestused ja kindlustab igaveseks imelised pulmahetked. Pulmafotod, mis teevad südame soojaks.',
        ahref: '/search/pulmafotograafia',
      },
      {
        name: 'Pere fotograafia',
        title: 'Pere fotograafia sessioonid',
        description: '',
        ahref: '/search/pere-fotograafia',
      },
      {
        name: 'Fotograaf',
        title: 'Fotograaf igaks hetkeks',
        description: '',
        ahref: '/search/fotograaf',
      },
      {
        name: 'Jõulu Fotograaf',
        title: 'Jõuluvõlu fotograaf',
        description:
          'Paiguta kõik kolleegid, sõbrad, pere või sugulased koos jõuluvanaga kuuse ette ja fotograaf tabab sellise pildi, mille saaks postkaardina laiali saata.',
        ahref: '/search/joulu-fotograaf',
      },
      {
        name: 'Stuudio fotosessioon',
        title: 'Isikupärased stuudio fotosessioonid',
        description:
          'Kordumatu stuudiosessioon, kohandatud alati just Sinu soovidele. Stuudio on loominguline platvorm, kus püüame Sinu sära kaamerasse!',
        ahref: '/search/stuudio-fotosessioon',
      },
      {
        name: '360 Videokaamera',
        title: '360-kraadi videokaamera',
        description:
          'Vii sündmuste jäädvustamine järgmisele tasemele 360-kraadi kaameratega, mis pakuvad erakordset pildikvaliteeti ja lihtsat otse jagamist kasutajatele.',
        ahref: '/search/360-videokaamera',
      },
      {
        name: 'Fotopeegel',
        title: 'Lõbus fotopeegel sündmusele',
        description:
          'Fotopeegli renditeenus​,​ mis lisab üritustele midagi meeldejäävat. Pärast pidu lahkuvad kõik külalised rõõmsalt​,​ taskutäie lahedate fotodega.',
        ahref: '/search/fotopeegel',
      },
      {
        name: 'Fotoboksi rent',
        title: 'Fotoboks või fotonurk',
        description:
          'Fotoboks on hitt igal üritusel! See talletab külaliste siiraid​,​ sädelevaid emotsioone. Pärast sündmust lahkuvad kõik​ taskutäie lahedate fotodega.',
        ahref: '/search/fotoboksi-rent',
      },
    ],
  },
  {
    name: 'TÖÖTOAD',
    title: 'Töötoad igale maitsele ja vanusele',
    ahref: '/search/tootoad',
    items: [
      {
        name: 'Toiduvalmistamise töötuba',
        title: 'Toiduvalmistamise töötuba, et muljet avaldada',
        description:
          'Valmista koos sõprade, pere või kolleegidega kooskokkamise töötubades maitsvaid roogasid ja hõrke maiustusi. Armastus käib ikka veel kõhu kaudu!',
        ahref: '/search/toiduvalmistamise-tootuba',
      },
      {
        name: 'Seltskonnamängud',
        title: 'Harivad ja lõbusad seltskonnamängud',
        description:
          'Lõbus viis aja veetmiseks koos sõprade, pere või kolleegidega. Mängud aitavad lahti lasta pingetest, luua tihedamaid suhteid ja parandada meeskonnatööd.',
        ahref: '/search/seltskonnamangud',
      },
      {
        name: 'Keraamika töötuba',
        title: 'Keraamika valmistamise töötuba',
        description:
          'Osale keraamika meistriklassi töötoas ja vii koju käsitsi valmistatud keraamika! Samm-sammult juhised ja praktiline kogemus erinevate keraamikatehnikatega.',
        ahref: '/search/keraamika-tootuba',
      },
      {
        name: 'Šokolaadi töötuba',
        title: 'Šokolaadi valmistamise töötuba',
        description:
          'Osale šokolaadi valmistamise töötoas ja avasta maiustuste maailm! Saad õppida ja meisterdada ise oma šokolaadi koos sõprade, pere või töökaaslastega.',
        ahref: '/search/sokolaadi-tootuba',
      },
      {
        name: 'Muusika töötuba',
        title: 'Muusika loomise ja nautimise töötuba',
        description:
          'Ükski teine kunstivorm ei suuda mõjutada meie meeleolu nii tugevalt kui muusika. See on tõeline võlujõud meie heaolu jaoks. Broneeri muusika töötuba.',
        ahref: '/search/muusika-tootuba',
      },
      {
        name: 'Käsitöö töötuba',
        title: 'Käsitöö töötuba igale maitsele',
        description:
          'Käsitöö õpetab kannatlikkust, loomingulisust ja tähelepanu detailidele ning loob võimaluse ühenduda teiste käsitööhuvilistega kogukondades ja töötubades.',
        ahref: '/search/kasitoo-tootuba',
      },
      {
        name: 'Maalimise töötuba',
        title: 'Maalimise töötuba loomingulisele hingele',
        description:
          'Maalimine on inspireeriv ja võimaldab väljendada oma emotsioone ja mõtteid visuaalsel viisil. Juhendatud maalimise töötoas saad luua endale midagi imelist!',
        ahref: '/search/maalimise-tootuba',
      },
      {
        name: 'Veini degusteerimine',
        title: 'Veini degusteerimine nautlejatele',
        description:
          'Sukeldu veinimaailma! Veini degusteerimine on nauditav ja hariv tegevus, mis võimaldab avastada erinevaid maitseid, aroome ja tekstuure.',
        ahref: '/search/veini-degusteerimine',
      },
      {
        name: 'Meelelahutuslik töötuba',
        title: 'Meelelahutuslik töötuba sõpradele',
        description:
          'Hea võimalus veeta lähedastega koos kvaliteetaega. Töötoad on selleks käed-külge võimalus. Tihtipeale saad peale töötuba endale midagi mälestuseks kaasa!',
        ahref: '/search/meelelahutuslik-tootuba',
      },
    ],
  },
  {
    name: 'LASTELE',
    title: 'Lõbusad ja arendavad hetked lastele',
    ahref: '/search/lastele',
    items: [
      {
        name: 'Mängutuba',
        title: 'Mängutuba lapse sünnipäevaks',
        description:
          'Mängutoad pakuvad lastele lõbusat ja interaktiivset keskkonda, kus nad saavad mängida ja avastada. Tee oma lapse sünnipäevapidu eriliseks!',
        ahref: '/search/mangutuba',
      },
      {
        name: 'Laste sünnipäev',
        title: 'Lõbus laste sünnipäev',
        description:
          'Laste sünnipäevad on täis naeru, mänge, näomaalinguid ja muid üllatusi pannes lapse tundma end erilisena oma tähtsal päeval.',
        ahref: '/search/laste-sunnipaev',
      },
      {
        name: 'Lapse sünnipäev õues',
        title: 'Lapse sünnipäev õues värskes õhus',
        description:
          'Laste sünnipäev õues pakub võimalusi aktiivseks tegevuseks, nagu pallimängud, takistusrada, aardejaht, näomaalingud, piknikud ja vabas õhus einestamine.',
        ahref: '/search/lapse-sunnipaev-oues',
      },
      {
        name: 'Näomaalingud',
        title: 'Põnevad näomaalingud',
        description:
          'Näomaalingud loovad lõbusa ja loomingulise atmosfääri sünnipäeval kui ka muul üritusel. Näo peale võib joonistada loomi, lilli või superkangelasi!',
        ahref: '/search/naomaalingud',
      },
      {
        name: 'Nukuteater',
        title: 'Nukuteater lapse sünnipäevale',
        description:
          'Lummava lavakujunduse ja käsitööna valminud nukkude teater jutustab ilusaid lugusid. Nukuteatris saavad lapsed kogeda põnevaid ja harivaid etendusi.',
        ahref: '/search/nukuteater',
      },
      {
        name: 'Jõuluvana',
        title: 'Muhe jõuluvana jõuludeks',
        description:
          'Jõuluvana tuleb lume ja põhjapõtradega koju, kooli või kontorisse ning loob piparkoogihõngulise jõulutunde. Üks õige jõulupidu ei möödu ilma jõuluvanata!',
        ahref: '/search/jouluvana',
      },
    ],
  },
  {
    name: 'ARTISTID',
    title: 'Artistid, kes löövad sündmuse särama',
    ahref: '/search/artistid',
    items: [
      {
        name: 'Laulja',
        title: 'Laulja meeleolu loomiseks',
        description:
          'Lauljad loovad atmosfääri, mis ühendab inimesi ja täidab ruumi positiivse energiaga. Nende etteasted toovad rõõmu ja elevust kõigile kohalviibijatele.',
        ahref: '/search/laulja',
      },
      {
        name: 'Eesti bändid',
        title: 'Eesti bändid esinema',
        description:
          'Eesti bändid on kodumaised talendid, kes toovad sündmusele lähedust ja autentsust. Luba neil mängida oma sündmusel parimat muusikat!',
        ahref: '/search/eesti-bandid',
      },
      {
        name: 'Pillimees sünnipäevale',
        title: 'Pillimees sünnipäevale meeleolu tekitajaks',
        description:
          'Pillimehed on sündmuste elavdajad, lisades unikaalset muusikalist meisterlikust ja dünaamilist energiat. Pillimees paneb kindlasti külaliste jalad tatsuma!',
        ahref: '/search/pillimees-sunnipaevale',
      },
      {
        name: 'DJ service',
        title: '',
        description: '',
        ahref: '/search/dj-service',
      },
      {
        name: 'Õhtujuhid/peojuhid',
        title: 'Õhtujuhid ja peojuhid',
        description:
          'Õhtujuhid on eksperdid, kes toovad oma karismaatilise isiksusega kaasa lõbusat ja kaasahaaravat atmosfääri. Kogenud peojuht muudab iga õhtu unustamatuks!',
        ahref: '/search/ohtujuhid-peojuhid',
      },
    ],
  },
  {
    name: 'ILU',
    title: '',
    ahref: '/search/ilu',
    items: [
      {
        name: 'Meik',
        title: 'Meik igaks elu sündmuseks',
        description:
          'Meigiartistid on professionaalid, kes loovad erinevat stiili meiki vastavaks sündmuseks. Eesmärk on tagada kaunis välimus ja enesekindlus!',
        ahref: '/search/meik',
      },
      {
        name: 'Soeng',
        title: '',
        description: '',
        ahref: '/search/soeng',
      },
      {
        name: 'Tüdrukute õhtu',
        title: 'Tüdrukute õhtu',
        description:
          'Tüdrukuteõhtuks leiad palju erinevaid võimalusi, alates iluhooldustest lõpetades kokteilide valmistamise töötubadega. Sina ja Su sõbrannad väärite seda!',
        ahref: '/search/tudrukute-ohtu',
      },
    ],
  },
  {
    name: 'SÖÖK/JOOK',
    title: 'Söök ja jook igale sündmusele',
    ahref: '/search/sook-jook',
    items: [
      {
        name: 'Pinjaata',
        title: 'Pinjaata',
        description:
          'See lõbus pinjaata traditsioon lisab sündmusele mängulise elemendi. Pinjaata tõotab olla meeldejääv kogemus!',
        ahref: '/search/pinjaata',
      },
      {
        name: 'Baariteenus',
        title: 'Professionaalne baariteenus',
        description:
          'Professionaalsed baarmenid loovad unikaalseid maitseelamusi ja teenindavad külalisi elegantselt, tagades meeldejääva ja maitseka kogemuse.',
        ahref: '/search/baariteenus',
      },
    ],
  },
];

export default dislyMenu;
