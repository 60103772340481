export const dislyMenu = [
  {
    name: 'PHOTO/VIDEO',
    title: 'Photos and video captures for every moment',
    ahref: '/search/photo-video',
    items: [
      {
        name: 'Wedding Photography',
        title: 'Wedding photography for special moments',
        description:
          'An experienced wedding photographer captures the most important memories and secures wonderful wedding moments forever. Wedding photos that warm the heart.',
        ahref: '/search/wedding-photography',
      },
      {
        name: 'Family Photography',
        title: 'Family photography sessions',
        description: '',
        ahref: '/search/family-photography',
      },
      {
        name: 'Photographer',
        title: 'Photographer for every moment',
        description: '',
        ahref: '/search/photographer',
      },
      {
        name: 'Christmas Photographer',
        title: 'Christmas magic photographer',
        description:
          'Gather all your colleagues, friends, family, or relatives with Santa Claus in front of the Christmas tree, and the photographer will capture a photo that you can send out as a postcard.',
        ahref: '/search/christmas-photographer',
      },
      {
        name: 'Studio Photo Session',
        title: 'Personalized studio photo sessions',
        description:
          'An unforgettable studio session, always tailored to your specific wishes. The studio is a creative platform where we strive to capture your brilliance on camera!',
        ahref: '/search/studio-photo-session',
      },
      {
        name: '360 Video Camera',
        title: '360-degree video camera',
        description:
          'Elevate event capture to the next level with 360-degree cameras that deliver exceptional image quality and seamless sharing directly with users.',
        ahref: '/search/360-video-camera',
      },
      {
        name: 'Photo Mirror',
        title: 'Fun photo mirror for events',
        description:
          'Photo mirror rental service that adds an unforgettable touch to events. After the party, all guests will leave with smiles and pockets full of cool photos.',
        ahref: '/search/photo-mirror',
      },
      {
        name: 'Photo Booth Rental',
        title: 'Photo booth or photo corner',
        description:
          'A photo booth is a hit at every event! It captures the genuine, sparkling emotions of guests. After the event, everyone leaves with a pocketful of cool photos.',
        ahref: '/search/photo-booth-rental',
      },
    ],
  },
  {
    name: 'WORKSHOPS',
    title: 'Workshops for every taste and age',
    ahref: '/search/workshops',
    items: [
      {
        name: 'Cooking Workshop',
        title: 'Cooking workshop to impress',
        description:
          'Prepare delicious dishes and exquisite desserts in cooking workshops with friends, family, or colleagues. Love still goes through the stomach!',
        ahref: '/search/cooking-workshop',
      },
      {
        name: 'Group Games',
        title: 'Educational and group board games',
        description:
          'A fun way to spend time with friends, family, or colleagues. Games help to relieve stress, build closer relationships, and improve teamwork.',
        ahref: '/search/board-games',
      },
      {
        name: 'Ceramics Workshop',
        title: 'Ceramics making workshop',
        description:
          'Participate in a ceramics masterclass workshop and take home handcrafted ceramics! Step-by-step instructions and practical experience with various ceramic techniques.',
        ahref: '/search/ceramics-workshop',
      },
      {
        name: 'Chocolate Workshop',
        title: 'Chocolate making workshop',
        description:
          'Participate in a chocolate-making workshop and discover the world of sweets! Learn and craft your own chocolate with friends, family, or coworkers.',
        ahref: '/search/chocolate-workshop',
      },
      {
        name: 'Music Workshop',
        title: 'Music creation and enjoyment workshop',
        description:
          'No other art form can affect our mood as strongly as music. It is a true magical power for our wellbeing. Book a music workshop.',
        ahref: '/search/music-workshop',
      },
      {
        name: 'Handycrafts Workshop',
        title: 'Handycrafts workshop for every taste',
        description:
          'Handycrafts teach patience, creativity, and attention to detail, and provide an opportunity to connect with other craft enthusiasts in communities and workshops.',
        ahref: '/search/crafts-workshop',
      },
      {
        name: 'Painting Workshop',
        title: 'Painting workshop for the creative soul',
        description:
          'Painting is inspiring and allows you to express your emotions and thoughts in a visual way. In a guided painting workshop, you can create something wonderful for yourself!',
        ahref: '/search/painting-workshop',
      },
      {
        name: 'Wine Tasting',
        title: 'Wine tasting for enthusiasts',
        description:
          'Dive into the world of wine! Wine tasting is an enjoyable and educational activity that allows you to discover different flavors, aromas, and textures.',
        ahref: '/search/wine-tasting',
      },
      {
        name: 'Entertaining Workshop',
        title: 'Entertaining workshop for friends',
        description:
          'A great opportunity to spend quality time with loved ones. Workshops are a hands-on opportunity. Often, you can take something memorable home after the workshop!',
        ahref: '/search/entertainment-workshop',
      },
    ],
  },
  {
    name: 'FOR CHILDREN',
    title: 'Fun and educational moments for children',
    ahref: '/search/for-children',
    items: [
      {
        name: 'Playrooms',
        title: "Playrooms for a childrens' birthday",
        description:
          "Playrooms provide children with a fun and interactive environment where they can play and explore. Make your child's birthday party special!",
        ahref: '/search/playrooms',
      },
      {
        name: "Children's Birthday",
        title: "Fun children's birthday",
        description:
          "Children's birthdays are filled with laughter, games, face painting, and other surprises, making the child feel special on their important day.",
        ahref: '/search/childrens-birthday',
      },
      {
        name: "Outdoor Childrens' Birthday",
        title: "Child's birthday outdoors in the fresh air",
        description:
          "An outdoor children's birthday offers opportunities for active activities, such as ball games, obstacle courses, treasure hunts, face painting, picnics, and dining outdoors.",
        ahref: '/search/outdoor-childs-birthday',
      },
      {
        name: 'Face Painting',
        title: 'Exciting face paintings',
        description:
          'Face paintings create a fun and creative atmosphere at birthdays and other events. You can draw animals, flowers, or superheroes on your face!',
        ahref: '/search/face-painting',
      },
      {
        name: 'Puppet Theater',
        title: "Puppet theater for childrens' birthday",
        description:
          'With enchanting stage design and handmade puppets, the theater tells beautiful stories. In puppet theater, children can experience exciting and educational performances.',
        ahref: '/search/puppet-theater',
      },
      {
        name: 'Santa Claus',
        title: 'Cheerful Santa Claus for Christmas',
        description:
          'Santa Claus comes with snow and reindeer to homes, schools, or offices, creating a gingerbread-scented Christmas atmosphere. No proper Christmas party is complete without Santa Claus!',
        ahref: '/search/santa-claus',
      },
    ],
  },
  {
    name: 'ARTISTS',
    title: 'Artists who make the event shine',
    ahref: '/search/artists',
    items: [
      {
        name: 'Singer',
        title: 'Singer for creating the mood',
        description:
          'Singers create an atmosphere that unites people and fills the room with positive energy. Their performances bring joy and excitement to all attendees.',
        ahref: '/search/singer',
      },
      {
        name: 'Estonian Bands',
        title: 'Estonian bands to perform',
        description:
          'Estonian bands are local talents who bring closeness and authenticity to the event. Let them play the best music at your event!',
        ahref: '/search/estonian-bands',
      },
      {
        name: 'Musician for Birthday',
        title: 'Musician for birthday to create the mood',
        description:
          "Musicians are event energizers, adding unique musical mastery and dynamic energy. A musician will definitely get the guests' feet tapping!",
        ahref: '/search/musician-for-birthday',
      },
      {
        name: 'DJ service',
        title: '',
        description: '',
        ahref: '/search/dj-service',
      },
      {
        name: 'Event Hosts',
        title: 'Hosts for events',
        description:
          'Hosts are experts who bring a fun and engaging atmosphere with their charismatic personality. An experienced party leader makes every evening unforgettable!',
        ahref: '/search/hosts-party-leaders',
      },
    ],
  },
  {
    name: 'BEAUTY',
    title: '',
    ahref: '/search/beauty',
    items: [
      {
        name: 'Makeup',
        title: 'Makeup for every life event',
        description:
          'Makeup artists are professionals who create various styles of makeup suitable for events. The goal is to ensure a beautiful appearance and confidence!',
        ahref: '/search/makeup',
      },
      {
        name: 'Hairstyling',
        title: '',
        description: '',
        ahref: '/search/hairstyling',
      },
      {
        name: "Girls' Night Out",
        title: "Girls' night out",
        description:
          "For a girls' night out, you'll find many different options, from beauty treatments to cocktail-making workshops. You and your girlfriends deserve it!",
        ahref: '/search/girls-night-out',
      },
    ],
  },
  {
    name: 'FOOD/DRINK',
    title: 'Food and drink for every event',
    ahref: '/search/food-drink',
    items: [
      {
        name: 'Piñata',
        title: 'Piñata',
        description:
          'This fun piñata tradition adds a playful element to the event. A piñata promises to be a memorable experience!',
        ahref: '/search/piñata',
      },
      {
        name: 'Bar Service',
        title: 'Professional bar service',
        description:
          'Professional bartenders create unique taste experiences and serve guests elegantly, ensuring a memorable and tasteful experience.',
        ahref: '/search/bar-service',
      },
    ],
  },
];

export default dislyMenu;
