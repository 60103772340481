/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { injectIntl, FormattedMessage } from '../../../util/reactIntl';
import { EE_LOCALE, propTypes, RU_LOCALE } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';

import {
  AvatarLarge,
  InlineTextButton,
  LanguageSelector,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
  NotificationBadge,
} from '../../../components';

import { dislyMenu as dislyMenu_en } from '../../../config/configMenu-en.js';
import { dislyMenu as dislyMenu_et } from '../../../config/configMenu-et.js';
import { dislyMenu as dislyMenu_ru } from '../../../config/configMenu-ru.js';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = injectIntl(props => {
  const {
    intl,
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);

  const mobileLanguageSelector = (
    <div className={css.mobileLanguageSelectorRow}>
      <LanguageSelector isAuthenticated={isAuthenticated} />
    </div>
  );

  let localisedMenu = dislyMenu_en;
  switch (intl.locale) {
    case EE_LOCALE:
      localisedMenu = dislyMenu_et;
      break;
    case RU_LOCALE:
      localisedMenu = dislyMenu_ru;
      break;
  }

  //  <p className={css.categoriesMenuSubDescription}>{subcategory.description}</p>
  const categoriesMenu = (
    <Menu>
      <MenuLabel>
        <span className={css.categoriesMenuRoot}>
          <FormattedMessage id="TopbarDesktop.mainMenu" />
        </span>
      </MenuLabel>
      <MenuContent contentClassName={css.categoriesMenuContent}>
        {localisedMenu.map((category, index) => (
          <MenuItem key={index} className={css.categoriesMenuItem}>
            <div className={css.categoriesMenuColumn}>
              <div className={css.categoriesMenuRootItem}>
                <a href={category.ahref} title={category.title}>
                  <h3 className={css.categoriesMenuRootName}>{category.name}</h3>
                  <p className={css.categoriesMenuRootDescription}>{category.title}</p>
                </a>
              </div>
              {category.items.map((subcategory, subIndex) => (
                <div key={subIndex} className={css.categoriesMenuSubItem}>
                  <a href={subcategory.ahref} title={subcategory.description}>
                    <span className={css.categoriesMenuSubName}>{subcategory.name}</span>
                  </a>
                </div>
              ))}
            </div>
          </MenuItem>
        ))}
      </MenuContent>
    </Menu>
  );

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.languageSelectorContainer}>{mobileLanguageSelector}</div>
        {categoriesMenu}
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
        </div>
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <div className={css.languageSelectorContainer}>{mobileLanguageSelector}</div>
      {categoriesMenu}
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('FavoriteListingsPage'))}
          name="FavoriteListingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.favoriteListingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        <div className={css.spacer} />
      </div>
      <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div>
    </div>
  );
});

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
