import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './DateInput.module.css';

const NextMonthIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootNextMonthIcon, className);

  return (
    <svg
      className={classes}
      width="5"
      height="8"
      viewBox="0 0 5 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.7 7.29997C1.38333 7.61664 1.02083 7.68748 0.6125 7.51248C0.204167 7.33747 0 7.02498 0 6.57498V1.42498C0 0.974975 0.204167 0.662475 0.6125 0.487475C1.02083 0.312475 1.38333 0.383309 1.7 0.699975L4.3 3.29998C4.4 3.39998 4.475 3.50831 4.525 3.62498C4.575 3.74164 4.6 3.86664 4.6 3.99998C4.6 4.13331 4.575 4.25831 4.525 4.37498C4.475 4.49164 4.4 4.59998 4.3 4.69998L1.7 7.29997Z"
        fill="#333333"
      />
    </svg>
  );
};

const { string } = PropTypes;

NextMonthIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

NextMonthIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default NextMonthIcon;
