import React from 'react';
import { bool, node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldRadioButton.module.css';

const IconRadioButton = props => {
  const { checkedClassName, invertColor } = props;
  const checkedClasses = invertColor
    ? classNames(css.checked, checkedClassName || css.checkedStyleInvertColor)
    : classNames(css.checked, checkedClassName || css.checkedStyle);

  return (
    <div>
      <svg className={props.className} width="14" height="14" xmlns="http://www.w3.org/2000/svg">
        <circle
          className={props.showAsRequired ? css.required : css.notChecked}
          cx="5"
          cy="19"
          r="6"
          transform="translate(2 -12)"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
        />

        <g className={checkedClasses} transform="translate(2 -12)" fill="none" fillRule="evenodd">
          <circle strokeWidth="2" cx="5" cy="19" r="6" />
          <circle fill="#FFF" fillRule="nonzero" cx="5" cy="19" r="3" />
        </g>
      </svg>
    </div>
  );
};

IconRadioButton.defaultProps = { className: null };

IconRadioButton.propTypes = { className: string };

const FieldRadioButtonComponent = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    checkedClassName,
    invertColor,
    id,
    label,
    showAsRequired,
    textClassName, // Add this line to extract textClassName from props
    ...rest
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const inputClasses = classNames(invertColor ? css.inputInvertColor : css.input);
  const textClasses = classNames(invertColor ? css.textInvertColor : css.text, textClassName); // Use textClassName here
  const labelClasses = classNames(invertColor ? css.labelInvertColor : css.label);
  const radioButtonProps = {
    id,
    className: inputClasses,
    component: 'input',
    type: 'radio',
    ...rest,
  };

  return (
    <span className={classes}>
      <Field {...radioButtonProps} />
      <label htmlFor={id} className={labelClasses}>
        <span className={css.radioButtonWrapper}>
          <IconRadioButton
            className={svgClassName}
            checkedClassName={checkedClassName}
            showAsRequired={showAsRequired}
            invertColor={invertColor}
          />
        </span>
        <span className={textClasses}>{label}</span>
      </label>
    </span>
  );
};

FieldRadioButtonComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  checkedClassName: null,
  label: null,
  invertColor: false,
};

FieldRadioButtonComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  checkedClassName: string,
  invertColor: bool,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several RadioButtones to an array of selected values
  name: string.isRequired,

  // RadioButton needs a value that is passed forward when user checks the RadioButton
  value: string.isRequired,
};

export default FieldRadioButtonComponent;
