import {
  CHECKOUT_STARTED,
  CHECKOUT_SUCCESS,
  LOCATION_CHANGED,
  USER_DATA_SUCCESS,
} from '../ducks/routing.duck';
import { PRICE_MODEL_PER_PERSON, PRICE_MODEL_PER_SERVICE } from '../util/types';
import moment from 'moment/moment';
import metaHandler from './meta';
// Create a Redux middleware from the given analytics handlers. Each
// handler should have the following methods:
//
function calculateOrderData(payload) {
  const currency = payload?.listing?.attributes?.price?.currency || 'EUR';
  const transactionId = payload?.id?.uuid || null;
  const price = payload?.listing?.attributes?.price?.amount / 100 || 0;
  const bookingStart = new Date(payload?.orderData?.bookingDates?.bookingStart);
  const bookingEnd = new Date(payload?.orderData?.bookingDates?.bookingEnd);
  const numberOfHours = (bookingEnd - bookingStart) / (3600 * 1000);

  let adjustedQuantity = numberOfHours;
  let adjustedPrice = price;

  const pricingModel = payload?.listing?.attributes?.publicData?.priceModel;
  const priceWeekends = payload?.listing?.attributes?.publicData?.priceWeekends;
  if (priceWeekends) {
    const dayOfWeek = moment(payload?.orderData?.bookingDates?.bookingStart).day();
    const dateIsWeekend = [6, 0].includes(dayOfWeek);
    if (dateIsWeekend) {
      adjustedPrice = priceWeekends / 100 || price;
    }
  }

  if (pricingModel === PRICE_MODEL_PER_PERSON) {
    adjustedQuantity = payload?.orderData?.people || 1;
  } else if (pricingModel === PRICE_MODEL_PER_SERVICE) {
    adjustedQuantity = 1;
  }

  const items = [
    {
      item_id: payload?.listing?.id?.uuid,
      item_name: payload?.listing?.attributes?.title,
      item_category: payload?.listing?.attributes?.publicData?.category[0],
      price: adjustedPrice,
      quantity: adjustedQuantity,
    },
  ];
  for (
    let i = 1;
    i <= 5 && i < (payload?.listing?.attributes?.publicData?.category?.length || 0);
    i++
  ) {
    items[0]['item_category' + i] = payload.listing.attributes.publicData.category[i];
  }
  return { transactionId, currency, adjustedQuantity, adjustedPrice, items };
}

// - trackPageView(canonicalPath, previousPath): called when the URL is changed
export const createMiddleware = handlers => store => next => action => {
  const { type, payload } = action;

  if (type === 'app/SearchPage/SEARCH_LISTINGS_SUCCESS') {
    const searchPage = store?.getState()?.SearchPage;
    metaHandler.trackSearch(searchPage);
  }

  if (type === LOCATION_CHANGED) {
    const previousPath = store?.getState()?.routing?.currentCanonicalPath;
    const { canonicalPath } = payload;
    handlers.forEach(handler => {
      handler.trackPageView(canonicalPath, previousPath);
    });
  }

  if (type === CHECKOUT_STARTED) {
    const { transactionId, currency, adjustedQuantity, adjustedPrice, items } = calculateOrderData(
      payload
    );
    handlers.forEach(handler => {
      handler.trackBeginCheckout(currency, adjustedPrice * adjustedQuantity, items);
    });
    metaHandler.trackBeginCheckout(payload, adjustedPrice * adjustedQuantity, currency);
  }

  if (type === CHECKOUT_SUCCESS) {
    const value = payload?.attributes?.payinTotal?.amount / 100 || 0;
    const { transactionId, currency, adjustedQuantity, adjustedPrice, items } = calculateOrderData(
      payload
    );
    handlers.forEach(handler => {
      handler.trackPurchase(currency, transactionId, value, items);
    });
    metaHandler.trackPurchase(payload, currency, value);
  }

  if (type === USER_DATA_SUCCESS) {
    handlers.forEach(handler => {
      const email = payload?.attributes?.email;
      const phoneNumber = payload?.attributes?.profile?.protectedData?.phoneNumber;
      const firstName = payload?.attributes?.profile?.firstName;
      const lastName = payload?.attributes?.profile?.lastName;
      handler.userData(email, phoneNumber, firstName, lastName);
    });
  }

  next(action);
};
