import React, { useState, useEffect } from 'react';
import { injectIntl } from '../../../util/reactIntl';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes, EE_LOCALE, RU_LOCALE } from '../../../util/types';

import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  LanguageSelector,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import { dislyMenu as dislyMenu_en } from '../../../config/configMenu-en.js';
import { dislyMenu as dislyMenu_et } from '../../../config/configMenu-et.js';
import { dislyMenu as dislyMenu_ru } from '../../../config/configMenu-ru.js';

import { default as iconPlus } from './iconPlus.svg';
import { default as iconInbox } from './iconInbox.svg';

import css from './TopbarDesktop.module.scss';

const TopbarDesktop = injectIntl(props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);
  const { locale } = intl;

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <img className={css.icon} src={iconInbox} />
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  let localisedMenu = dislyMenu_en;
  switch (intl.locale) {
    case EE_LOCALE:
      localisedMenu = dislyMenu_et;
      break;
    case RU_LOCALE:
      localisedMenu = dislyMenu_ru;
      break;
  }

  //  <p className={css.categoriesMenuSubDescription}>{subcategory.description}</p>
  const categoriesMenu = (
    <Menu>
      <MenuLabel>
        <span className={css.categoriesMenuRoot}>
          <FormattedMessage id="TopbarDesktop.mainMenu" />
        </span>
      </MenuLabel>
      <MenuContent contentClassName={css.categoriesMenuContent}>
        {localisedMenu.map((category, index) => (
          <MenuItem key={index} className={css.categoriesMenuItem}>
            <div className={css.categoriesMenuColumn}>
              <div className={css.categoriesMenuRootItem}>
                <a href={category.ahref} title={category.title}>
                  <h3 className={css.categoriesMenuRootName}>{category.name}</h3>
                  <p className={css.categoriesMenuRootDescription}>{category.title}</p>
                </a>
              </div>
              {category.items.map((subcategory, subIndex) => (
                <div key={subIndex} className={css.categoriesMenuSubItem}>
                  <a href={subcategory.ahref} title={subcategory.description}>
                    <span className={css.categoriesMenuSubName}>{subcategory.name}</span>
                  </a>
                </div>
              ))}
            </div>
          </MenuItem>
        ))}
      </MenuContent>
    </Menu>
  );

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="FavoriteListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('FavoriteListingsPage'))}
            name="FavoriteListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.favoriteListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const isNotAuthCreateListingLink = (
    <>
      {!isAuthenticated && (
        <div className={css.createListingLink}>
          <NamedLink name="NewListingPage">
            <span className={css.createListing}>
              <FormattedMessage id="TopbarDesktop.createListing" />
            </span>
          </NamedLink>
        </div>
      )}
    </>
  );

  const isAuthCreateListingLink = (
    <>
      {isAuthenticated && (
        <NamedLink name="NewListingPage" className={css.isAuthcreateListingLink}>
          <span className={css.createListingAuth}>
            <img className={css.icon} src={iconPlus} />
            <FormattedMessage id="TopbarDesktop.createListing" />
          </span>
        </NamedLink>
      )}
    </>
  );

  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        format="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      />
      {categoriesMenu}
      {search}
      {isAuthenticated && <LanguageSelector isAuthenticated={isAuthenticated} />}
      {isAuthCreateListingLink}
      {inboxLink}
      {signupLink}
      {loginLink}
      {isNotAuthCreateListingLink}
      {profileMenu}
      {!isAuthenticated && <LanguageSelector isAuthenticated={isAuthenticated} />}
    </nav>
  );
});

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
