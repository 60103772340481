import React, { useState, useEffect } from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import { default as iconCookie } from './iconCookie.svg';

import css from './CookieConsent.module.scss';
const hubspotAcceptButtonId = '#hs-eu-confirmation-button';
const hubspotConsentCookieName = '__hs_cookie_cat_pref';
const hubspotConsentCookieValue = '1:true,2:true,3:true';
const dislyConsentCookieName = '_dislyCookieConsent';
const dislyConsentCookieValue = '1';

const CookieConsent = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (typeof document === 'undefined') {
      return;
    }

    const cookies = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});

    let hs_cookie = cookies[hubspotConsentCookieName];
    let disly_cookie = cookies[dislyConsentCookieName];
    if (
      (!hs_cookie && !disly_cookie) ||
      (hs_cookie != hubspotConsentCookieValue && disly_cookie != dislyConsentCookieValue)
    ) {
      setShow(true);
    }
  }, []);

  const onAcceptCookies = () => {
    saveCookieConsent();
    setShow(false);
  };

  const onDeclineCookies = () => {
    setShow(false);
    if (typeof window !== 'undefined') {
      let _hsp = (window._hsp = window._hsp || []);
      if (_hsp && _hsp.push) {
        _hsp.push(['revokeCookieConsent']);
      }
    }
    if (typeof document === 'undefined') {
      return;
    }
    document.cookie.split(';').forEach(cookie => {
      let name = cookie.trim().split('=')[0];
      let domainParts = location.hostname.split('.');
      while (domainParts.length > 0) {
        let domain = domainParts.join('.');
        document.cookie = `${name}=;max-age=0;domain=.${domain};path=/`;
        domainParts.shift();
      }
    });
  };

  const saveCookieConsent = () => {
    if (typeof document === 'undefined') {
      return;
    }
    document.cookie = `${hubspotConsentCookieName}=${hubspotConsentCookieValue};max-age=31536000;path=/`;
    saveDislyCookieConsent();
    let acceptButton = document.querySelector(hubspotAcceptButtonId);
    if (acceptButton) {
      acceptButton.click();
    }
  };

  const saveDislyCookieConsent = () => {
    // We create date object and modify it to show date 10 years into the future.
    let expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);
    // Save the cookie with expiration date
    document.cookie =
      `${dislyConsentCookieName}=${dislyConsentCookieValue}; path=/; expires=` +
      expirationDate.toGMTString();
  };

  const render = () => {
    const isServer = typeof window === 'undefined';

    // Server side doesn't know about cookie consent
    if (isServer || !show) {
      return null;
    } else {
      return (
        <>
          <div className={css.desktopRoot}>
            <div className={css.row}>
              <div className={css.colIcon}>
                <img className={css.iconCookie} src={iconCookie} />
              </div>
              <div className={css.colTitleMessage}>
                <div className={css.row}>
                  <div className={css.col12}>
                    <div className={css.title}>
                      <FormattedMessage id="CookieConsent.title" />
                    </div>
                  </div>
                  <div className={css.col12}>
                    <div className={css.message}>
                      <FormattedMessage id="CookieConsent.message" />
                    </div>
                  </div>
                </div>
              </div>
              <div className={css.colButtons}>
                <div className={css.row}>
                  <div className={css.col6}>
                    <button className={css.acceptBtn} onClick={onAcceptCookies}>
                      <FormattedMessage id="CookieConsent.accept" />
                    </button>
                  </div>
                  <div className={css.col6}>
                    <button className={css.declineBtn} onClick={onDeclineCookies}>
                      <FormattedMessage id="CookieConsent.decline" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={css.isOpenInPortal}>
            <div className={css.scrollLayer}>
              <div className={css.mobileRoot}>
                <div className={css.mobileContainer}>
                  <div className={css.row}>
                    <div className={css.col12}>
                      <img className={css.iconCookie} src={iconCookie} />
                    </div>
                    <div className={css.col12}>
                      <div className={css.title}>
                        <FormattedMessage id="CookieConsent.title" />
                      </div>
                    </div>
                    <div className={css.col12}>
                      <div className={css.message}>
                        <FormattedMessage id="CookieConsent.message" />
                      </div>
                    </div>
                    <div className={css.col12}>
                      <button className={css.acceptBtn} onClick={onAcceptCookies}>
                        <FormattedMessage id="CookieConsent.accept" />
                      </button>
                    </div>
                    <div className={css.col12}>
                      <button className={css.declineBtn} onClick={onDeclineCookies}>
                        <FormattedMessage id="CookieConsent.decline" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return render();
};

export default CookieConsent;
