import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import VerificationInput from 'react-verification-input';
import css from './OTPVerificationForm.module.scss';
import { smsSendOTPCode, smsVerifyOTPCode } from '../../util/api';
import { FormattedMessage } from 'react-intl';
import IconSpinner from '../IconSpinner/IconSpinner';

const OTPVerificationForm = forwardRef((props, ref) => {
  const { onOTPCodeVerified } = props;

  const [verificationInProgress, setVerificationInProgress] = useState(false); // [1
  const [initialOTPCodeSent, setInitialOTPCodeSent] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);

  useImperativeHandle(ref, () => ({
    sendOTPCode(phoneNumber) {
      if (!initialOTPCodeSent) {
        setPhoneNumber(phoneNumber);
      }
    },
    resetVerificationInProgress() {
      setVerificationInProgress(false);
    },
  }));

  useEffect(() => {
    if (phoneNumber !== null) {
      onSmsSendOTPCode();
    }
  }, [phoneNumber]);

  const onSmsSendOTPCode = () => {
    smsSendOTPCode({ phoneNumber })
      .then(status => {
        if (status === 'pending') {
          setInitialOTPCodeSent(true);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const onSmsVerifyOTPCode = otpCode => {
    if (otpCode.length === 6) {
      setVerificationInProgress(true);
      smsVerifyOTPCode({ phoneNumber, otpCode })
        .then(status => {
          setVerificationInProgress(false);
          if (status === 'approved') {
            onOTPCodeVerified();
          }
        })
        .catch(error => {
          setVerificationInProgress(false);
          console.error(error);
        });
    }
  };

  return (
    <div id={css.otpVerificationForm}>
      <div className={css.row}>
        <div className={css.col12}>
          <h6 className={css.verifyPhoneNumberHeading}>
            <FormattedMessage id="OTPVerificationForm.verifyPhoneNumberHeading" />
          </h6>
          <p className={css.phoneNumberPlaceholder}>
            <FormattedMessage id="OTPVerificationForm.codeSentToMessage" />
            <strong> {phoneNumber}</strong>
          </p>
        </div>
        <div className={css.col12}>
          <div className={css.inputContainer}>
            {verificationInProgress ? (
              <>
                <IconSpinner />
              </>
            ) : (
              <VerificationInput
                validChars={'0-9'}
                length={6}
                passwordMode={true}
                placeholder={''}
                onChange={onSmsVerifyOTPCode}
              />
            )}
          </div>
        </div>
        <div className={css.col12}>
          <p className={css.resendCodeText}>
            <span>
              <FormattedMessage id="OTPVerificationForm.noCodeMessage" />{' '}
            </span>
            <a onClick={() => onSmsSendOTPCode()}>
              <FormattedMessage id="OTPVerificationForm.resendAgain" />
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
});

const { any, func } = PropTypes;

OTPVerificationForm.defaultProps = {
  ref: null,
  setSignupOPTCodeVerified: null,
  onOTPCodeVerified: null,
};

OTPVerificationForm.propTypes = {
  ref: any,
  setSignupOPTCodeVerified: func,
  onOTPCodeVerified: func,
};

export default OTPVerificationForm;
