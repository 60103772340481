import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './DateInput.module.css';

const PreviousMonthIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootPreviousMonthIcon, className);

  return (
    <svg
      className={classes}
      width="5"
      height="8"
      viewBox="0 0 5 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.30039 7.29997L0.700391 4.69998C0.600391 4.59998 0.525391 4.49164 0.475391 4.37498C0.425391 4.25831 0.400391 4.13331 0.400391 3.99998C0.400391 3.86664 0.425391 3.74164 0.475391 3.62498C0.525391 3.50831 0.600391 3.39998 0.700391 3.29998L3.30039 0.699975C3.61706 0.383309 3.97956 0.312475 4.38789 0.487475C4.79622 0.662475 5.00039 0.974975 5.00039 1.42498V6.57498C5.00039 7.02498 4.79622 7.33747 4.38789 7.51248C3.97956 7.68748 3.61706 7.61664 3.30039 7.29997Z"
        fill="#333333"
      />
    </svg>
  );
};

const { string } = PropTypes;

PreviousMonthIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

PreviousMonthIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default PreviousMonthIcon;
