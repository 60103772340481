export const dislyMenu = [
  {
    name: 'ФОТО/ВИДЕО',
    title: 'Фото и видео на каждый момент',
    ahref: '/search/фото-видео',
    items: [
      {
        name: 'Свадебная фотосессия',
        title: 'Свадебная фотосессия для особых моментов',
        description:
          'Опытный свадебный фотограф запечатлеет самые важные воспоминания и навсегда сохранит чудесные свадебные моменты. Свадебные фотографии, которые согреют сердце.',
        ahref: '/search/свадебная-фотосессия',
      },
      {
        name: 'Семейная фотосессия',
        title: 'Семейные фотосессии',
        description: '',
        ahref: '/search/семейная-фотосессия',
      },
      {
        name: 'Фотограф',
        title: 'Фотограф на каждый момент',
        description: '',
        ahref: '/search/фотограф',
      },
      {
        name: 'Рождественский фотограф',
        title: 'Фотограф рождественской магии',
        description:
          'Соберите всех коллег, друзей, членов семьи или родственников вместе с Дедом Морозом у елки, и фотограф сделает такой снимок, который можно будет разослать как открытку.',
        ahref: '/search/рождественский-фотограф',
      },
      {
        name: 'Фотосессия в студии',
        title: 'Персональные студийные фотосессии',
        description:
          'Неповторимая студийная фотосессия, всегда адаптированная к вашим желаниям. Студия - это творческая площадка, где мы стараемся запечатлеть ваш блеск на камеру!',
        ahref: '/search/фотосессия-в-студии',
      },
      {
        name: '360 видеокамера',
        title: '360-градусная видеокамера',
        description:
          'Повысьте уровень запечатления событий с помощью 360-градусных камер, обеспечивающих непревзойденное качество изображения и мгновенный обмен контентом с участниками',
        ahref: '/search/360-видеокамера',
      },
      {
        name: 'Фотозеркало',
        title: 'Веселое фотозеркало для мероприятий',
        description:
          'Аренда фотозеркала – это то, что сделает ваше мероприятие незабываемым! Ваши гости будут в восторге, уходя с праздника с карманами, полными крутых фотографий.',
        ahref: '/search/фотозеркало',
      },
      {
        name: 'Аренда фотобокса',
        title: 'Фотобокс или фотоуголок',
        description:
          'Фотобокс - хит на каждом мероприятии! Он запечатлит искренние, сияющие эмоции ваших гостей. После события все уйдут с карманами, полными крутых фотографий.',
        ahref: '/search/аренда-фотобокса',
      },
    ],
  },
  {
    name: 'МАСТЕР-КЛАССЫ',
    title: 'Мастер-классы на любой вкус и возраст',
    ahref: '/search/мастер-классы',
    items: [
      {
        name: 'Кулинарный мастер-Класс',
        title: 'Кулинарный мастер-класс, чтобы произвести впечатление',
        description:
          'Готовьте вкусные блюда и изысканные десерты на кулинарных мастер-классах вместе с друзьями, семьей или коллегами. Любовь все еще проходит через желудок!',
        ahref: '/search/кулинарный-мастер-класс',
      },
      {
        name: 'Групповые развлечения',
        title: 'Образовательные и веселые развлечения',
        description:
          'Веселый способ провести время с друзьями, семьей или коллегами. Групповые развлечения помогают снять стресс, налаживать близкие отношения и улучшать командную работу.',
        ahref: '/search/групповые-развлечения',
      },
      {
        name: 'Мастер-класс по керамике',
        title: 'Мастер-класс по керамики',
        description:
          'Участвуйте в мастер-классе по керамике и заберите домой ручную керамику! Пошаговые инструкции и практический опыт с различными техниками керамики.',
        ahref: '/search/мастер-класс-по-керамике',
      },
      {
        name: 'Мастер-класс по шоколаду',
        title: 'Мастер-класс по изготовлению шоколада',
        description:
          'Участвуйте в мастер-классе по изготовлению шоколада и откройте для себя мир сладостей! Учите и создавайте свой шоколад вместе с друзьями, семьей или коллегами.',
        ahref: '/search/мастер-класс-по-шоколаду',
      },
      {
        name: 'Музыкальный мастер-Класс',
        title: 'Мастер-класс по созданию и наслаждению музыкой',
        description:
          'Ни одна другая форма искусства не может так сильно влиять на наше настроение, как музыка. Это настоящая магическая сила для нашего благополучия. Забронируй музыкальный мастер-класс.',
        ahref: '/search/музыкальный-мастер-класс',
      },
      {
        name: 'Мастер-класс по рукоделию',
        title: 'Мастер-класс по рукоделию на любой вкус',
        description:
          'Рукоделие учит терпению, творчеству и вниманию к деталям, и предоставляет возможность связаться с другими энтузиастами рукоделия в сообществах и на мастер-классах.',
        ahref: '/search/мастер-класс-по-рукоделию',
      },
      {
        name: 'Мастер-класс по живописи',
        title: 'Мастер-класс по живописи для творческих душ',
        description:
          'Живопись вдохновляет и позволяет выразить свои эмоции и мысли визуальным способом. На руководствуемом мастер-классе по живописи вы можете создать что-то великолепное для себя!',
        ahref: '/search/мастер-класс-по-живописи',
      },
      {
        name: 'Дегустация вина',
        title: 'Дегустация вина для энтузиастов',
        description:
          'Погрузитесь в мир вина! Дегустация вина - это приятное и познавательное занятие, которое позволяет открыть для себя новые вкусы, ароматы и текстуры.',
        ahref: '/search/дегустация-вина',
      },
      {
        name: 'Развлекательные мастер-классы',
        title: 'Развлекательные мастер-классы для друзей',
        description:
          'Отличная возможность провести качественное время с близкими. Мастер-классы предоставляют возможность практического участия. Часто после мастер-класса вы можете взять с собой что-то запоминающееся!',
        ahref: '/search/развлекательные-мастер-классы',
      },
    ],
  },
  {
    name: 'ДЛЯ ДЕТЕЙ',
    title: 'Веселые и развивающие моменты для детей',
    ahref: '/search/для-детей',
    items: [
      {
        name: 'Игровые комнаты',
        title: 'Игровые комнаты для детского дня рождения',
        description:
          'Игровые комнаты предоставляют детям веселую и интерактивную среду, где они могут играть и исследовать. Сделайте день рождения вашего ребенка особенным!',
        ahref: '/search/игровые-комнаты',
      },
      {
        name: 'Детский день рождения',
        title: 'Веселый детский день рождения',
        description:
          'Детские дни рождения наполнены смехом, играми, аквагримом и другими сюрпризами, заставляя ребенка чувствовать себя особенным в его важный день.',
        ahref: '/search/детский-день-рождения',
      },
      {
        name: 'Детский день рождения на свежем воздухе',
        title: 'Детский день рождения на свежем воздухе',
        description:
          'Детский день рождения на свежем воздухе предлагает возможности для активных занятий, таких как игры с мячом, полосы препятствий, поиски сокровищ, аквагрим и пикники на свежем воздухе.',
        ahref: '/search/детский-день-рождения-на-свежем-воздухе',
      },
      {
        name: 'Аквагрим',
        title: 'Веселый аквагрим',
        description:
          'Аквагрим создает веселую и творческую атмосферу как на день рождения, так и на любом другом мероприятии. На лице можно нарисовать животных, цветы или супергероев!',
        ahref: '/search/аквагрим',
      },
      {
        name: 'Кукольный театр',
        title: 'Кукольный театр для детского дня рождения',
        description:
          'Театр с очаровательными декорациями и куклами ручной работы рассказывает красивые истории. В кукольном театре дети могут посмотреть захватывающие и познавательные представления.',
        ahref: '/search/кукольный-театр',
      },
      {
        name: 'Дед Мороз',
        title: 'Веселый Дед Мороз на Новый Год',
        description:
          'Дед Мороз приходит домой, в школу или офис на санях, запряженных оленями, и создает атмосферу Рождества с ароматом имбирных пряников. Ни один настоящий рождественский праздник не обходится без Деда Мороза!',
        ahref: '/search/дед-мороз',
      },
    ],
  },
  {
    name: 'АРТИСТЫ',
    title: 'Артисты, которые делают событие ярким',
    ahref: '/search/артисты',
    items: [
      {
        name: 'Певцы',
        title: 'Певцы для создания настроения',
        description:
          'Певцы создают атмосферу, которая объединяет людей и наполняет помещение позитивной энергией. Их выступления приносят радость и волнение всем присутствующим.',
        ahref: '/search/певцы',
      },
      {
        name: 'Эстонские группы',
        title: 'Эстонские группы',
        description:
          'Эстонские группы - это местные таланты, которые добавят вашему мероприятию близости и аутентичности. Пригласите их играть на своем мероприятии лучшую музыку!',
        ahref: '/search/эстонские-группы',
      },
      {
        name: 'Музыкант на день рождения',
        title: 'Музыкант на день рождения для создания настроения',
        description:
          'Музыканты - это оживляющие события люди, добавляющие уникальное музыкальное мастерство и динамичную энергию. Музыкант обязательно заставит гостей танцевать!',
        ahref: '/search/музыкант-на-день-рождения',
      },
      {
        name: 'DJ',
        title: '',
        description: '',
        ahref: '/search/dj',
      },
      {
        name: 'Ведущие/организаторы вечеринок',
        title: 'Ведущие и организаторы вечеринок',
        description:
          'Ведущие мероприятий - это эксперты, которые своим харизматичным обаянием создают веселую и увлекательную атмосферу. Опытный ведущий сделает любой вечер незабываемым!',
        ahref: '/search/ведущие-организаторы-вечеринок',
      },
    ],
  },
  {
    name: 'БЬЮТИ',
    title: '',
    ahref: '/search/бьюти',
    items: [
      {
        name: 'Макияж',
        title: 'Макияж на все случаи жизни',
        description:
          'Визажисты - это профессионалы, которые создают макияж в разных стилях, подходящий для любого события. Цель - обеспечить красивый внешний вид и уверенность в себе!',
        ahref: '/search/макияж',
      },
      {
        name: 'Прическа',
        title: '',
        description: '',
        ahref: '/search/прическа',
      },
      {
        name: 'Девичник',
        title: 'Девичник',
        description:
          'Для девичника вы найдете множество вариантов, от спа-процедур до мастер-классов по приготовлению коктейлей. Вы и ваши подруги этого заслуживаете!',
        ahref: '/search/девичник',
      },
    ],
  },
  {
    name: 'ЕДА/НАПИТКИ',
    title: 'Еда и напитки на любое мероприятие',
    ahref: '/search/еда-напитки',
    items: [
      {
        name: 'Пиньята',
        title: 'Пиньята',
        description:
          'Эта веселая традиция с пиньятой добавит игровой элемент в ваше мероприятие. Пиньята обещает стать незабываемым событием!',
        ahref: '/search/piñata',
      },
      {
        name: 'Барный сервис',
        title: 'Профессиональный барный сервис',
        description:
          'Профессиональные бармены создают уникальные вкусовые впечатления и обслуживают гостей элегантно, гарантируя незабываемый и вкусный опыт.',
        ahref: '/search/барный-сервис',
      },
    ],
  },
];

export default dislyMenu;
