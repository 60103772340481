import { useEffect, useState } from 'react';
import css from './ParagraphToggle.module.scss';
import { number, string } from 'prop-types';
import { default as iconReadMore } from './iconReadMore.svg';
import { FormattedMessage } from 'react-intl';

const ParagraphToggle = props => {
  const { paragraph, showChar } = props;

  const [showParagraph2, setShowParagraph2] = useState(false);
  const [paragraphSplit, setParagraphSplit] = useState(false);
  const [paragraph1, setParagraph1] = useState();
  const [paragraph2, setParagraph2] = useState();

  const readMoreText = (
    <>
      <span>
        <FormattedMessage id="ParagraphToggle.readMore" />
      </span>
      <span>
        {' '}
        <img src={iconReadMore} />
      </span>
    </>
  );
  const readLessText = (
    <>
      <span>- </span>
      <span>
        <FormattedMessage id="ParagraphToggle.readLess" />
      </span>
    </>
  );
  const [toggleText, setToggleText] = useState();

  useEffect(() => {
    if (paragraph) {
      if (paragraph.length > showChar) {
        setParagraphSplit(true);
        setParagraph1(paragraph.substring(0, showChar));
        setParagraph2(paragraph.substring(showChar, paragraph.length - showChar));
      }
    }

    if (showParagraph2) {
      setToggleText(readLessText);
    } else {
      setToggleText(readMoreText);
    }
  }, [paragraph, showParagraph2]);

  return (
    <>
      {paragraphSplit ? (
        <>
          <span>{paragraph1}</span>
          {showParagraph2 && <span>{paragraph2}</span>}
          <br />
          <a className={css.toggleText} onClick={() => setShowParagraph2(!showParagraph2)}>
            <strong>{toggleText}</strong>
          </a>
        </>
      ) : (
        paragraph
      )}
    </>
  );
};

ParagraphToggle.defaultProps = {
  paragraph: null,
  showChar: null,
};

ParagraphToggle.propTypes = {
  paragraph: string.isRequired,
  showChar: number.isRequired,
};

export default ParagraphToggle;
