import React from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { Avatar, ReviewRating, UserDisplayName } from '../../components';
import { propTypes } from '../../util/types';

import css from './Reviews.module.scss';

const Review = props => {
  const { review, intl } = props;

  const date = review.attributes.createdAt;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });

  return (
    <div className={css.review}>
      <div className={css.row}>
        <div className={css.col2}>
          <Avatar className={css.avatar} user={review.author} />
        </div>
        <div className={css.col10}>
          <div className={css.sectionAuthorDate}>
            <UserDisplayName className={css.reviewAuthor} user={review.author} intl={intl} />
            <div className={css.reviewDate}>{dateString}</div>
          </div>
        </div>
      </div>
      <div className={css.row}>
        <div className={css.col12}>
          <p className={css.reviewContent}>{review.attributes.content}</p>
        </div>
        {/* <ReviewRating
          rating={review.attributes.rating}
          className={css.mobileReviewRating}
          reviewStarClassName={css.reviewRatingStar}
        />
        <p className={css.reviewContent}>{review.attributes.content}</p>
        <p className={css.reviewInfo}>
          <span className={css.separator}>•</span>
          {dateString}
          <span className={css.desktopSeparator}>•</span>
          <span className={css.desktopReviewRatingWrapper}>
            <ReviewRating
              rating={review.attributes.rating}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
          </span>
        </p> */}
      </div>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const { className, rootClassName, reviews, intl } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={css.reviewsContainer}>
      <div className={`${css.row} ${classes}`}>
        {reviews.map(r => {
          return (
            <div key={`Review_${r.id.uuid}`} className={css.colReview}>
              <Review review={r} intl={intl} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
