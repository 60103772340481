import { FormattedMessage, injectIntl } from 'react-intl';
import css from './EventLocationForm.module.scss';
import { FieldCheckbox, FieldTextInput, FieldRadioButton } from '..';
import { required } from '../../util/validators';
import { H4 } from '../../containers/PageBuilder/Primitives/Heading';
import { bool, object, shape, string } from 'prop-types';
import { EVENT_LOCATION_BUYER, EVENT_LOCATION_SUPPLIER } from '../../util/types';

const LocationCheckboxes = props => {
  const { children, isProvider } = props;

  return (
    <div className={css.locationCheckboxes}>
      <H4 as="h3" className={css.eventlocationTitle}>
        {isProvider ? (
          <FormattedMessage id="EventLocationForm.editListingLocationTitle" />
        ) : (
          <FormattedMessage id="EventLocationForm.checkoutLocationTitle" />
        )}
      </H4>
      <div className={css.dividerLine}></div>
      {children}
    </div>
  );
};

const EventLocationForm = injectIntl(props => {
  const {
    intl,
    formId,
    showAddressFields,
    isProvider,
    fieldNames,
    buyerEventLocationOptions,
  } = props;

  const { showBoth, showBuyerOnly, showSupplierOnly } = buyerEventLocationOptions;

  const addressRequiredMessage = intl.formatMessage({
    id: 'EventLocationForm.addressRequired',
  });
  const cityRequiredMessage = intl.formatMessage({
    id: 'EventLocationForm.cityRequired',
  });
  const stateRequiredMessage = intl.formatMessage({
    id: 'EventLocationForm.stateRequired',
  });
  const postalRequiredMessage = intl.formatMessage({
    id: 'EventLocationForm.postalRequired',
  });
  const countryRequiredMessage = intl.formatMessage({
    id: 'EventLocationForm.countryRequired',
  });
  const optionalText = intl.formatMessage({
    id: 'EventLocationForm.optionalText',
  });

  const whosPlaceSupplier = (
    <FieldRadioButton
      name="whosPlace"
      id={'supplierLocation'}
      className={css.checkboxLocation}
      textClassName={css.checkLocationText}
      label={intl.formatMessage({ id: 'EventLocationForm.checkoutSupplierLocationLabel' })}
      value={EVENT_LOCATION_SUPPLIER}
    />
  );
  const whosPlaceBuyer = (
    <FieldRadioButton
      name="whosPlace"
      id={'buyerLocation'}
      className={css.checkboxLocation}
      textClassName={css.checkLocationText}
      label={intl.formatMessage({ id: 'EventLocationForm.checkoutBuyerLocationLabel' })}
      value={EVENT_LOCATION_BUYER}
    />
  );

  return (
    <>
      {isProvider ? (
        <LocationCheckboxes isProvider={isProvider}>
          <FieldCheckbox
            name="buyerLocation"
            id={'buyerLocation'}
            className={css.checkboxLocation}
            textClassName={css.checkLocationText}
            label={intl.formatMessage({ id: 'EventLocationForm.editListingBuyerLocationLabel' })}
          />
          <FieldCheckbox
            name="supplierLocation"
            id={'supplierLocation'}
            className={css.checkboxLocation}
            textClassName={css.checkLocationText}
            label={intl.formatMessage({ id: 'EventLocationForm.editListingsupplierLocationLabel' })}
          />
        </LocationCheckboxes>
      ) : showBoth ? (
        <LocationCheckboxes isProvider={isProvider}>
          {whosPlaceSupplier}
          {whosPlaceBuyer}
        </LocationCheckboxes>
      ) : showSupplierOnly ? (
        <LocationCheckboxes isProvider={isProvider}>
          {showSupplierOnly && whosPlaceSupplier}
        </LocationCheckboxes>
      ) : showBuyerOnly ? (
        <LocationCheckboxes isProvider={isProvider}>
          {showBuyerOnly && whosPlaceBuyer}
        </LocationCheckboxes>
      ) : null}
      {showAddressFields && (
        <>
          <div className={css.row}>
            <div className={css.colAddressField}>
              <FieldTextInput
                className={css.addressField}
                type="text"
                name={fieldNames.address1}
                id={`${formId}${fieldNames.address1}`}
                label={intl.formatMessage({ id: 'EventLocationForm.address' })}
                placeholder={intl.formatMessage({
                  id: 'EventLocationForm.addressPlaceholder',
                })}
                validate={required(addressRequiredMessage)}
              />
            </div>
            <div className={css.colAddressField}>
              <FieldTextInput
                className={css.addressField}
                type="text"
                name={fieldNames.address2}
                id={`${formId}${fieldNames.address2}`}
                label={intl.formatMessage({ id: 'EventLocationForm.building' }, { optionalText })}
                placeholder={intl.formatMessage({
                  id: 'EventLocationForm.buildingPlaceholder',
                })}
              />
            </div>
            <div className={css.colAddressField}>
              <FieldTextInput
                className={css.addressField}
                type="text"
                name={fieldNames.city}
                id={`${formId}${fieldNames.city}`}
                label={intl.formatMessage({ id: 'EventLocationForm.city' })}
                placeholder={intl.formatMessage({
                  id: 'EventLocationForm.cityPlaceholder',
                })}
                validate={required(cityRequiredMessage)}
              />
            </div>
            <div className={css.colAddressField}>
              <FieldTextInput
                className={css.addressField}
                type="text"
                name={fieldNames.state}
                id={`${formId}${fieldNames.state}`}
                label={intl.formatMessage({ id: 'EventLocationForm.state' })}
                placeholder={intl.formatMessage({
                  id: 'EventLocationForm.statePlaceholder',
                })}
                validate={required(stateRequiredMessage)}
              />
            </div>
            <div className={css.colAddressField}>
              <FieldTextInput
                className={css.addressField}
                type="text"
                name={fieldNames.postal}
                id={`${formId}${fieldNames.postal}`}
                label={intl.formatMessage({ id: 'EventLocationForm.postal' })}
                placeholder={intl.formatMessage({
                  id: 'EventLocationForm.postalPlaceholder',
                })}
                validate={required(postalRequiredMessage)}
              />
            </div>
            <div className={css.colAddressField}>
              <FieldTextInput
                className={css.addressField}
                type="text"
                name={fieldNames.country}
                id={`${formId}${fieldNames.country}`}
                label={intl.formatMessage({ id: 'EventLocationForm.country' })}
                placeholder={intl.formatMessage({
                  id: 'EventLocationForm.countryPlaceholder',
                })}
                validate={required(countryRequiredMessage)}
              />
            </div>

            <div className={css.col12}>
              <FieldTextInput
                className={css.addressField}
                type="textarea"
                name={fieldNames.comment}
                id={`${formId}${fieldNames.comment}`}
                label={intl.formatMessage({ id: 'EventLocationForm.comment' })}
                placeholder={intl.formatMessage({
                  id: 'EventLocationForm.commentPlaceholder',
                })}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
});

EventLocationForm.defaultProps = {
  formId: '',
  showAddressFields: false,
  isProvider: false,
  fieldNames: null,
  buyerEventLocationOptions: {
    showSupplierOnly: false,
    showBuyerOnly: false,
    showBoth: false,
  },
};

EventLocationForm.propTypes = {
  formId: string.isRequired,
  showAddressFields: bool.isRequired,
  isProvider: bool.isRequired,
  fieldNames: object.isRequired,
  buyerEventLocationOptions: shape({
    showSupplierOnly: bool,
    showBuyerOnly: bool,
    showBoth: bool,
  }),
};

export default EventLocationForm;
