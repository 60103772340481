import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { camelize } from '../../util/string';
import { EE_LOCALE, RU_LOCALE } from '../../util/types';

const TOS_ASSET_NAME_DEFAULT = 'terms-of-service';
const PRIVACY_POLICY_ASSET_NAME_DEFAULT = 'privacy-policy';

export let TOS_ASSET_NAME = TOS_ASSET_NAME_DEFAULT;
export let PRIVACY_POLICY_ASSET_NAME = PRIVACY_POLICY_ASSET_NAME_DEFAULT;

export const loadData = (params, search, config) => dispatch => {
  const { locale } = config.localization;

  if (locale === EE_LOCALE || locale === RU_LOCALE) {
    TOS_ASSET_NAME = TOS_ASSET_NAME_DEFAULT + '-' + locale;
    PRIVACY_POLICY_ASSET_NAME = PRIVACY_POLICY_ASSET_NAME_DEFAULT + '-' + locale;
  }

  const pageAsset = {
    [camelize(TOS_ASSET_NAME)]: `content/pages/${TOS_ASSET_NAME}.json`,
    [camelize(PRIVACY_POLICY_ASSET_NAME)]: `content/pages/${PRIVACY_POLICY_ASSET_NAME}.json`,
  };
  return dispatch(fetchPageAssets(pageAsset, true));
};
