import React from 'react';
import PropTypes from 'prop-types';

import css from './IconKeys.module.css';

const IconKeys = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      width="44"
      height="40"
      viewBox="0 0 44 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.65 39.9502L22.85 24.1502H22.55C21.6833 26.1502 20.3833 27.7919 18.65 29.0752C16.9167 30.3585 14.7 31.0002 12 31.0002C8.66667 31.0002 5.83333 29.8335 3.5 27.5002C1.16667 25.1669 0 22.3335 0 19.0002C0 16.8002 0.583333 14.7169 1.75 12.7502C2.91667 10.7835 4.61667 9.2502 6.85 8.1502L1.05 2.3502L3.2 0.200195L40.8 37.8002L38.65 39.9502ZM32.6 25.3502L28.4 21.1502H32.6V25.3502ZM38.25 31.0002L35.25 28.0002H35.7V21.1594H41V16.8502H24.1L21.1 13.8502H44V24.1502H38.7V31.0002H38.25ZM12 28.0002C13.8667 28.0002 15.5917 27.4002 17.175 26.2002C18.7583 25.0002 19.8167 23.5002 20.35 21.7002L9.15 10.4502C7.15 11.1169 5.625 12.2419 4.575 13.8252C3.525 15.4085 3 17.1335 3 19.0002C3 21.5002 3.875 23.6252 5.625 25.3752C7.375 27.1252 9.5 28.0002 12 28.0002ZM12 22.4002C11.0333 22.4002 10.225 22.0752 9.575 21.4252C8.925 20.7752 8.6 19.9669 8.6 19.0002C8.6 18.0335 8.925 17.2252 9.575 16.5752C10.225 15.9252 11.0333 15.6002 12 15.6002C12.9667 15.6002 13.775 15.9252 14.425 16.5752C15.075 17.2252 15.4 18.0335 15.4 19.0002C15.4 19.9669 15.075 20.7752 14.425 21.4252C13.775 22.0752 12.9667 22.4002 12 22.4002Z"
        fill="black"
      />
    </svg>
  );
};

IconKeys.defaultProps = { className: null };

const { string } = PropTypes;

IconKeys.propTypes = {
  className: string,
};

export default IconKeys;
