export class LoggingAnalyticsHandler {
  trackBeginCheckout(currency, value, items) {
    console.log('Analytics add to cart:', currency, value, items);
  }
  trackPurchase(currency, transaction_id, value, items) {
    console.log('Analytics purchase:', currency, transaction_id, value, items);
  }
  trackPageView(url) {
    console.log('Analytics page view:', url);
  }
  userData(email, phone_number, firstName, lastName) {
    console.log('Analytics user data:', email, phone_number, firstName, lastName);
  }
}

// Google Analytics 4 (GA4) using gtag.js script, which is included in util/includeScripts.js
export class GoogleAnalyticsHandler {
  trackBeginCheckout(currency, value, items) {
    if (window.gtag) {
      window.gtag('event', 'begin_checkout', {
        currency: currency,
        value: value,
        items: items,
      });
    }
  }

  trackPurchase(currency, transaction_id, value, items) {
    if (window.gtag) {
      window.gtag('event', 'purchase', {
        currency: currency,
        transaction_id: transaction_id,
        value: value,
        items: items,
      });
    }
  }

  userData(email, phone_number, firstName, lastName) {
    if (window.gtag) {
      window.gtag('set', 'user_data', {
        email: email,
        phone_number: phone_number,
        address: {
          first_name: firstName,
          last_name: lastName,
        },
      });
    }
  }

  trackPageView(canonicalPath, previousPath) {
    // GA4 property. Manually send page_view events
    // https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
    // Note 1: You should turn "Enhanced measurement" off.
    //         It attaches own listeners to elements and that breaks in-app navigation.
    // Note 2: If previousPath is null (just after page load), gtag script sends page_view event automatically.
    //         Only in-app navigation needs to be sent manually from SPA.
    // Note 3: Timeout is needed because gtag script picks up <title>,
    //         and location change event happens before initial rendering.
    if (previousPath && window.gtag) {
      window.setTimeout(() => {
        window.gtag('event', 'page_view', {
          page_path: canonicalPath,
        });
      }, 300);
    }
  }
}
