import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import {
  propTypes,
  LINE_ITEM_HOUR,
  LINE_ITEM_PER_SERVICE,
  LINE_ITEM_PER_PERSON,
} from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemBasePriceMaybe = props => {
  const { lineItems, code, intl } = props;

  const isPerPerson = code === LINE_ITEM_PER_PERSON;
  const isPerHour = code === LINE_ITEM_HOUR;
  const isPerService = code === LINE_ITEM_PER_SERVICE;
  const translationKey = isPerPerson
    ? 'OrderBreakdown.baseUnitPerPerson'
    : isPerHour
    ? 'OrderBreakdown.baseUnitHour'
    : isPerService
    ? 'OrderBreakdown.baseUnitPerService'
    : 'OrderBreakdown.baseUnitQuantity';

  // Find correct line-item for given code prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/hour', or 'line-item/item'
  // These are defined in '../../util/types';
  const unitPurchase = lineItems.find(item => item.code === code && !item.reversal);

  const quantity = unitPurchase ? unitPurchase?.quantity?.toString() : null;
  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  return total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage
          id={translationKey}
          values={{ unitPrice, quantity, seats: unitPurchase.seats }}
        />
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
