export class MetaHandler {
  async trackSearch(searchPage) {
    if ('undefined' !== typeof window) {
      const ReactPixel = await this.getPixel();
      if (searchPage) {
        const { currentPageResultIds, searchParams } = searchPage;
        const data = {
          search_string: searchParams.keywords,
          content_ids: currentPageResultIds.map(v => v.uuid).join(','),
          content_category: searchParams?.pub_category?.split(':')?.join(''),
        };
        ReactPixel.init(process.env.FACEBOOK_META_PIXEL_ID);
        ReactPixel.track('Search', data);
      }
    }
  }

  async trackBeginCheckout(payload, price, currency) {
    if (typeof window !== 'undefined') {
      const ReactPixel = await this.getPixel();
      ReactPixel.init(process.env.FACEBOOK_META_PIXEL_ID);
      /*
      content_category, content_ids, contents, currency, num_items, value
       */
      const data = {
        content_category: payload?.listing?.attributes?.publicData?.category[0],
        contents_ids: payload?.listing?.id?.uuid,
        currency: currency,
        num_items: 1,
        value: price,
      };
      ReactPixel.track('InitiateCheckout', data);
    }
  }

  async trackPurchase(payload, currency, price) {
    if (typeof window !== 'undefined') {
      const reactPixel = await this.getPixel();
      reactPixel.init(process.env.FACEBOOK_META_PIXEL_ID);
      // content_category, content_ids, contents, currency, num_items, value
      const data = {
        content_category: payload?.listing?.attributes?.publicData?.category[0],
        contents_ids: payload?.listing?.id?.uuid,
        currency: currency,
        num_items: 1,
        value: price,
      };
      reactPixel.track('Purchase', data);
    }
  }

  async trackAddToWishList(id, title) {
    if (typeof window !== 'undefined') {
      const reactPixel = await this.getPixel();
      reactPixel.init(process.env.FACEBOOK_META_PIXEL_ID);
      const data = {
        id,
        title,
      };
      reactPixel.track('AddToWishList', data);
    }
  }

  async getPixel() {
    const reactPixel = await import('react-facebook-pixel');
    return reactPixel.default;
  }
}

export default new MetaHandler();
