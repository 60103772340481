import React from 'react';
import css from './LanguageSelector.module.scss';
import {
  DEFAULT_LOCALE,
  EE_LOCALE,
  EN_LOCALE,
  RU_LOCALE,
  SELECTED_BROWSER_LANGUAGE,
} from '../../util/types';
import { bool } from 'prop-types';
import { injectIntl } from '../../util/reactIntl';
import { useDispatch } from 'react-redux';
import { updateProfileLocale } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

const isTestEnv = process.env.NODE_ENV === 'test';

const LanguageSelector = injectIntl(props => {
  const { intl, isAuthenticated } = props;
  const { locale } = intl;

  const dispatch = useDispatch();

  const changeLanguage = e => {
    if (e.target.value === '') return;

    const newLocale = e.target.value;
    const updateLocalePromise = isAuthenticated
      ? dispatch(updateProfileLocale({ protectedData: { profileLocale: newLocale } }))
      : new Promise((resolve, reject) => {
          localStorage.setItem(SELECTED_BROWSER_LANGUAGE, newLocale);
          if (localStorage.getItem(SELECTED_BROWSER_LANGUAGE) === newLocale) {
            resolve();
          } else {
            reject(new Error('Failed to set locale in local storage'));
          }
        });

    updateLocalePromise
      .then(() => {
        // Remove language prefix from the current URL
        const currentUrl = new URL(window.location.href);
        const urlPathname = currentUrl.pathname;
        const urlWithoutLangPrefix = urlPathname.replace(/^\/(en|et|ru)/, '/');

        // Construct the new URL without the language prefix and navigate
        const newUrl = new URL(currentUrl);
        newUrl.pathname = urlWithoutLangPrefix;
        window.location.href = newUrl.toString();
      })
      .catch(error => {
        console.error(`Failed to change language: ${error}`);
      });
  };

  return (
    <div className={css.languageSelectorDropdown}>
      {
        <select value={locale} className={css.languageSelector} onChange={changeLanguage}>
          <option value={EE_LOCALE}>EST</option>
          <option value={EN_LOCALE}>ENG</option>
          <option value={RU_LOCALE}>RUS</option>
        </select>
      }
    </div>
  );
});

LanguageSelector.defaultProps = {
  isAuthenticated: false,
};

LanguageSelector.propTypes = {
  isAuthenticated: bool.isRequired,
};

export default LanguageSelector;
